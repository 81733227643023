import { ContentState, convertToRaw } from 'draft-js'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { TextInterface } from 'common/types/entities/TextInterface'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { updateEntity } from 'client/store/page/pageActions'
import pageSelectors from 'client/store/page/pageSelectors'
import { generateId } from 'client/utils/createStructureNew'
import { generateHtmlAttrId } from 'client/utils/createStructureUtils'
import BaseEntity from '../../core/BaseEntity/BaseEntityNew'
import TextEditor, { TextEditorUpdatePayload } from '../../core/TextEditorNew'
import TextErrorBoundary from './TextErrorBoundary'

type TextProps = {
  entity: TextInterface
  isMoveUpAllowed: boolean
  isMoveDownAllowed: boolean
}

function Text({ entity, isMoveUpAllowed, isMoveDownAllowed }: TextProps) {
  const dispatch = useDispatch()
  const idEditing = useManagement(m =>
    managementSelectors.isEditing(m, entity.id),
  )
  const [isDebug, setDebug] = useState(false)
  const { isMobile } = useDeviceModeContext()
  const linkColor = usePage(pageSelectors.getGlobalLinkColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const textColor = usePage(pageSelectors.getGlobalTextColor)

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const isDebug = searchParams.get('debug')
    setDebug(Boolean(isDebug))
  }, [])

  function update(payload: TextEditorUpdatePayload) {
    if (isDebug) {
      ;(window as any).Rollbar.info(`entity ${entity.id} update`, {
        entity: JSON.stringify({
          ...entity,
          ...payload,
        }),
      })
    }

    dispatch(
      updateEntity({
        ...entity,
        ...payload,
      }),
    )
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <TextErrorBoundary entity={entity}>
        <TextEditor
          attrId={entity.htmlAttrId}
          isEditing={idEditing}
          rawContentState={entity.rawContentState}
          update={update}
          padding={entity.padding}
          textAlign={entity.textAlign}
          editorTextAlign={
            isMobile
              ? entity.mobileTextAlign || entity.textAlign
              : entity.textAlign
          }
          textColor={textColor}
          linkColor={linkColor}
          fontSize={entity.fontSize || globalFontSize}
          fontStyle={entity.fontStyle}
          fontFamily={entity.fontFamily}
          fontWeight={entity.fontWeight}
          lineHeight={entity.lineHeight || globalLineHeight}
          mobilePadding={entity.mobilePadding}
          mobileFontSize={entity.mobileFontSize || globalMobileFontSize}
          mobileLineHeight={entity.mobileLineHeight || globalMobileLineHeight}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontWeight={entity.mobileFontWeight}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          letterSpacing={entity.letterSpacing}
          mobileLetterSpacing={entity.mobileLetterSpacing}
        />
      </TextErrorBoundary>
    </BaseEntity>
  )
}

export function createText(
  parentId: string,
  masterBlockId?: MasterBlockId,
  text?: string,
): TextInterface {
  const entity: TextInterface = {
    id: generateId(),
    type: EntityTypeEnum.Text,
    parentId: parentId,
    masterBlockId: masterBlockId,
    fontSize: 20,
    textAlign: 'center',
    rawContentState:
      '{"entityMap":{},"blocks":[{"key":"7gjsu",' +
      '"text":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem dolore, alias, ' +
      'numquam enim ab voluptate id quam harum ducimus cupiditate similique quisquam et deserunt, ' +
      'recusandae.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
    mobileFontSize: 16,
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.Text),
  }

  if (text) {
    entity.rawContentState = JSON.stringify(
      convertToRaw(ContentState.createFromText(text)),
    )
  }

  return entity
}

export function getTextFontProperties(entity: TextInterface) {
  const fontProperties = []

  if (entity.fontFamily) {
    fontProperties.push({
      fontFamily: entity.fontFamily,
      fontStyle: entity.fontStyle,
      fontWeight: entity.fontWeight,
    })
  }

  if (entity.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileFontFamily,
      fontStyle: entity.mobileFontStyle,
      fontWeight: entity.mobileFontWeight,
    })
  }

  if (entity.fontFamily && entity.rawContentState.includes('"style":"BOLD"')) {
    fontProperties.push({
      fontFamily: entity.fontFamily,
      fontStyle: 'normal',
      fontWeight: '700',
    })
  }

  if (
    entity.fontFamily &&
    entity.rawContentState.includes('"style":"ITALIC"')
  ) {
    fontProperties.push({
      fontFamily: entity.fontFamily,
      fontStyle: 'italic',
      fontWeight: entity.fontWeight,
    })
  }

  return fontProperties
}

export function getTextCustomFontProperties(entity: TextInterface) {
  const fontProperties = []

  if (entity.fontFamily) {
    fontProperties.push({
      fontFamily: entity.fontFamily,
      fontFileId: entity.fontFileId,
    })
  }

  if (entity.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileFontFamily,
      fontFileId: entity.mobileFontFileId,
    })
  }

  return fontProperties
}

export default memo(Text)
