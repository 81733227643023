import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyEventInterface } from 'common/components/entities/Calendar/calendar-interface'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { MarginType } from 'common/types/styleTypes'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import Select from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useMyEvents } from 'client/hooks/use-my-events'
import { BookingStepsSwitcher } from './booking-steps-switcher'

export interface BookingCalendarSettingsProps {
  entity: BookingCalendarInterface
}

const BookingCalendarSettings = ({ entity }: BookingCalendarSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<BookingCalendarInterface>()
  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()
  const { events, isFetching } = useMyEvents({
    onSuccess: events => {
      if (events.length) {
        if (entity.eventId) {
          const event = events.find(({ id }) => id === entity.eventId)
          if (event) setSelectedEvent(event)
          return
        }

        if (events.length === 1) {
          setSelectedEvent(events[0])
          onEventSelect(events[0])
          return
        }

        setError(t('validation.page.calendar.event_not_selected'))
        return
      }
      setError(t('entity_settings.calendar.events_empty_list_error'))
    },
  })
  const [error, setError] = useState('')

  const [selectedEvent, setSelectedEvent] = useState<MyEventInterface | null>(
    null,
  )

  const onEventSelect = async (event: MyEventInterface) => {
    setSelectedEvent(event)
    const updatedEntity = {
      ...entity,
      eventId: event.id,
    }
    updateEntity(updatedEntity)
    if (error) setError('')
  }

  return (
    <React.Fragment>
      <BookingStepsSwitcher entity={entity} />
      <GroupTitle>entity_settings.booking_calendar.event</GroupTitle>
      <Select
        isMulti={false}
        options={events?.map(({ name, id }) => ({ label: name, value: id }))}
        selectedOption={
          selectedEvent
            ? { label: selectedEvent.name, value: selectedEvent.id }
            : null
        }
        update={event =>
          event && onEventSelect({ id: event.value, name: event.label })
        }
        styles={{
          indicatorSeparator: () => ({}),
        }}
        isLoading={isFetching}
        error={error}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity?.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={value =>
          updateProp(isMobile ? 'mobileMargin' : 'margin')(value as MarginType)
        }
      />

      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateProp('titlesColor')}
        mobileUpdate={updateProp('mobileTitlesColor')}
        color={entity.titlesColor}
        mobileColor={entity.mobileTitlesColor}
        label={'entity_settings.calendar.titles_color'}
      />
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
        label={'entity_settings.calendar.text_color'}
      />
      <ColorPicker
        update={updateProp('availableSlotBackgroundColor')}
        color={entity.availableSlotBackgroundColor}
        label={'entity_settings.calendar.available_slot_bg_color'}
      />
      <ColorPicker
        update={color => {
          updateProp('selectedSlotBackgroundColor')(color)
        }}
        color={entity.selectedSlotBackgroundColor}
        label={'entity_settings.calendar.selected_slot_bg_color'}
      />

      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default BookingCalendarSettings
