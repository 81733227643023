import { slugs } from 'common/constants/inputTypes'
import { pageTypes } from 'common/constants/pageTypes'
import { paymentProcessorsRequiredFields } from 'common/constants/paymentProcessorsRequiredFields'
import { buttonActionsTypes } from 'common/constants/settings'
import structureTypes from 'common/constants/structureTypes'
import toolBoxTypes from 'common/constants/toolBoxTypes'
import * as entityUtils from 'common/utils/entityUtils'
import { checker, validator } from 'common/utils/validator'
import { getAllPopupIdsFromRaw } from 'client/components/core/TextEditor/utils'
import LibraryElementEnum from 'client/enums/LibraryElementEnum'
import i18n from 'client/i18n'
import {
  getAllDescendants,
  getByType,
  getId,
  getOptionPopup,
  getFieldSlug,
  getChildrenEntities,
  getEntityById,
  hasNoAutoLoad,
  hasOpenPopupAction,
  hasSendFormAction,
  isButton,
  isInput,
} from 'client/reducers/pageReducer'
import { getFirstEntityByType } from 'client/store/page/pageSelectors'
import { getProduct } from 'client/store/payment/paymentSelectors'
import { PageTypeEnum } from '../../common/enums/PageTypeEnum'
import EntityTypeEnum from '../../common/enums/entityTypeEnum'
import { PageValidationErrorNew } from '../../common/errors/PageValidationErrorNew'

const toolBoxTypesKeys = Object.keys(toolBoxTypes).concat(['BLOCK'])
const structureTypesKeys = Object.keys(structureTypes)
const typedEntityTypes = Object.values(EntityTypeEnum)

const PAGE_SIZE_BYTES_LIMIT = 4_718_592 // 4.5Mb Nginx has 5mb limit

const filterSectionLayout = type =>
  toolBoxTypes.TOOLBOX_SECTION !== type &&
  type !== LibraryElementEnum.LibrarySection

const filterBlogContent = type =>
  [
    toolBoxTypes.TOOLBOX_BLOG_CONTENT_PLACEHOLDER,
    LibraryElementEnum.LibraryBlogContentPlaceholder,
    toolBoxTypes.TOOLBOX_BLOG_POST_BODY,
    LibraryElementEnum.LibraryBlogPostBody,
    structureTypes.BLOG_CONTENT_PLACEHOLDER,
    EntityTypeEnum.BlogContentPlaceholder,
    structureTypes.BLOG_POST_CONTENT_PLACEHOLDER,
    EntityTypeEnum.BlogPostContentPlaceholder,
    structureTypes.BLOG_POST_BODY,
  ].includes(type) === false

const containerAllowedTypes = toolBoxTypesKeys
  .filter(filterSectionLayout)
  .concat(structureTypesKeys)
  .concat(typedEntityTypes)
  .filter(t => t !== structureTypes.SECTION && t !== EntityTypeEnum.Section)

// eslint-disable-next-line import/prefer-default-export
export const getAllowedTypes = type => {
  switch (type) {
    case structureTypes.BODY:
    case structureTypes.BLOG_POST_BODY:
    case structureTypes.BLOG_PAGE_BODY:
    case structureTypes.BLOG_POST_LAYOUT_BODY:
      return toolBoxTypesKeys
        .concat(structureTypesKeys)
        .concat(typedEntityTypes)
    case structureTypes.COLUMN:
    case EntityTypeEnum.Column:
    case structureTypes.CONTENT_BOX:
    case EntityTypeEnum.ContentBox:
    case structureTypes.INLINE:
    case structureTypes.POPUP:
    case structureTypes.SECTION:
    case EntityTypeEnum.Section:
    case structureTypes.SURVEY:
    case EntityTypeEnum.Survey:
    case structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT:
    case structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN:
    case structureTypes.ORDER_BUMP:
    case EntityTypeEnum.ContactUs:
    case EntityTypeEnum.Carousel:
      return containerAllowedTypes
    case EntityTypeEnum.CarouselItem: {
      return containerAllowedTypes
        .filter(entityType => EntityTypeEnum.Carousel !== entityType)
        .filter(filterBlogContent)
    }
    case EntityTypeEnum.FaqItem:
      return containerAllowedTypes.filter(filterBlogContent)
    default:
      return []
  }
}

const flatArray = (acc, val) => acc.concat(val)

const checkContentLengthNotExceeded = pageState => {
  console.log('length', JSON.stringify(pageState).length)
  return JSON.stringify(pageState).length < PAGE_SIZE_BYTES_LIMIT
}

const checkHasNotMultiplePaymentMethods = state => {
  const paymentMethodsOld = getByType(state, structureTypes.PAYMENT_METHOD)
  const paymentMethods = getByType(state, EntityTypeEnum.PaymentMethod)
  return paymentMethodsOld.concat(paymentMethods).length <= 1
}

const checkHasProductElementWhenStateHasProduct = (pageState, paymentState) => {
  if (
    pageState.type === PageTypeEnum.OfferPage &&
    paymentState &&
    getProduct(paymentState) &&
    !getFirstEntityByType(pageState, EntityTypeEnum.Product)
  ) {
    return false
  }

  return true
}

const checkHasNotMultipleCalendars = state => {
  const calendarEntities = getByType(state, EntityTypeEnum.BookingCalendar)
  return calendarEntities.length <= 1
}

const checkCalendarEventSelected = state => {
  const calendarEntities = getByType(state, EntityTypeEnum.BookingCalendar)
  if (!calendarEntities.length) return true

  const calendarEntity = calendarEntities.find(
    entity => entity.type === EntityTypeEnum.BookingCalendar,
  )
  return !!calendarEntity.eventId
}

const checkNoUnUsedExtraFields = state => {
  // The problem
  // We have fields and buttons that are placed not in any popups
  // and unused popup (no openers and no autoLoad) that might has extra fields

  // 1 find unused popup with no openers and no auto load
  // 1.1 check popups without auto load
  const popups = getByType(state, structureTypes.POPUP).filter(hasNoAutoLoad)
  // no popups - no problems
  if (popups.length === 0) {
    return true
  }
  // 1.2 ok, popups exists, lets check for openers
  const buttonsPopupsIds = getByType(state, structureTypes.BUTTON)
    .filter(hasOpenPopupAction)
    .map(getOptionPopup)

  const textHeadlinePopupIds = getByType(state, EntityTypeEnum.Headline)
    .map(text => getAllPopupIdsFromRaw(text.rawContentState))
    .reduce(flatArray, [])

  const bulletPopupIds = getByType(state, structureTypes.BULLET_LIST)
    .map(text => getAllPopupIdsFromRaw(text.options.rawContentState))
    .reduce(flatArray, [])

  const headlinePopupIds = getByType(state, EntityTypeEnum.Headline)
    .map(text => getAllPopupIdsFromRaw(text.rawContentState))
    .reduce(flatArray, [])

  // Filter workflow example
  // button [2]
  // headline [4]
  // [1 , 2, 3, 4] popups
  const popupWithoutOpeners = popups
    .filter(popup => buttonsPopupsIds.includes(popup.id) === false)
    .filter(popup => textHeadlinePopupIds.includes(popup.id) === false)
    .filter(popup => bulletPopupIds.includes(popup.id) === false)
    .filter(popup => headlinePopupIds.includes(popup.id) === false)
  // no popup without openers, it's ok
  if (popupWithoutOpeners.length === 0) {
    return true
  }
  // 2. Find existing fields that are not in popups
  //    with submit button
  // 2.1 find all popup fields
  const popupFields = popups
    .map(getAllDescendantsByPredicate(state, isInput))
    .reduce(flatArray, [])
  const popupFieldsIds = popupFields.map(getId)
  // 2.2 find all popup buttons
  const popupButtons = popups
    .map(getAllDescendantsByPredicate(state, isButton))
    .reduce(flatArray, [])
  const popupButtonIds = popupButtons.map(getId)
  // 2.3 find all fields on the page
  const pageFields = getByType(state, structureTypes.FORM_INPUT)
  // 2.4. find all buttons (with action send form) on the page
  const pageSendFormButtons = getByType(state, structureTypes.BUTTON).filter(
    hasSendFormAction,
  )
  // 2.5 page fields
  const notInPopupFields = pageFields.filter(
    input => popupFieldsIds.includes(input.id) === false,
  )
  // 2.6 page buttons
  const notInPopupButtons = pageSendFormButtons.filter(
    button => popupButtonIds.includes(button.id) === false,
  )
  // no inputs and button - ok
  if (notInPopupFields.length === 0 && notInPopupButtons.length === 0) {
    return true
  }

  const pageFieldsSlugs = notInPopupFields.map(getFieldSlug)
  const popupFieldsSlugs = popupFields.map(getFieldSlug)
  // we need to check that popup inputs slugs are included in the page inputs slugs
  const diff = popupFieldsSlugs.filter(
    field => pageFieldsSlugs.includes(field) === false,
  )
  // popups difference - bad
  if (diff.length > 0) {
    return false
  }

  return true
}

const checkBlogPostContentOnePlaceholder = state => {
  if (state.type === pageTypes.blogPostLayout) {
    const entities = getByType(
      state,
      structureTypes.BLOG_POST_CONTENT_PLACEHOLDER,
    )
    const newEntities = getByType(
      state,
      EntityTypeEnum.BlogPostContentPlaceholder,
    )
    return entities.concat(newEntities).length === 1
  }

  return true
}

function checkUniqueBlogPostListing(state) {
  if (
    state.type === pageTypes.blogHome ||
    state.type === pageTypes.blogStatic
  ) {
    let popupBlogPostListingsValidation = true
    const pageBlogPostListings = getByType(
      state,
      EntityTypeEnum.BlogPostListing,
    )
    //check blog post listings in popups
    pageBlogPostListings.forEach(entity => {
      const ascendantPopup = entityUtils.getAscendantPopup(state, entity)
      if (ascendantPopup && popupBlogPostListingsValidation) {
        const visibleListings = entityUtils.getVisibleBlogPostListings(
          state,
          false,
          ascendantPopup.id,
        )
        popupBlogPostListingsValidation = visibleListings.length <= 1
      }
    })
    //check blog post listings visible in mobile
    const mobileBlogPostListingsValidation =
      entityUtils.getVisibleBlogPostListings(state, false).length <= 1
    //check blog post listings visible in desktop
    const desktopBlogPostListingsValidation =
      entityUtils.getVisibleBlogPostListings(state, true).length <= 1

    return (
      mobileBlogPostListingsValidation &&
      desktopBlogPostListingsValidation &&
      popupBlogPostListingsValidation
    )
  }
  return true
}

const checkBlogPageLayoutOnePlaceholder = state => {
  if (state.type === pageTypes.blogLayout) {
    const entities = getByType(state, structureTypes.BLOG_CONTENT_PLACEHOLDER)

    const newEntities = getByType(state, EntityTypeEnum.BlogContentPlaceholder)
    return entities.concat(newEntities).length === 1
  }

  return true
}

const checkOneCustomLogin = state => {
  if (state.type === pageTypes.customLogin) {
    const entities = getByType(state, EntityTypeEnum.CustomLogin)
    return entities.length === 1
  }

  return true
}

const checkCustomLoginHasNotMultipleFieldsInLoginMode = state => {
  if (state.type === pageTypes.customLogin && checkOneCustomLogin(state)) {
    const customLoginEntity = getByType(state, EntityTypeEnum.CustomLogin)[0]
    const loginStructure = getEntityById(state, customLoginEntity.childIds[0])
    const loginModeEntities = getChildrenEntities(
      state,
      loginStructure.childIds,
    )
    let sctructureChecker = []
    loginModeEntities.forEach(entity => {
      if (entity.type === structureTypes.FORM_INPUT) {
        if (
          entity.options.slug === slugs.EMAIL ||
          entity.options.slug === slugs.PASSWORD
        ) {
          sctructureChecker.push(1)
        }
      }
      if (
        entity.type === structureTypes.BUTTON &&
        entity.options.action === buttonActionsTypes.login
      ) {
        sctructureChecker.push(1)
      }
    })
    // check if there extra or less required entities
    return sctructureChecker.join() == [1, 1, 1].join()
  }
  return true
}

const checkCustomLoginHasNotMultipleFieldsInConfirmRegistrationMode = state => {
  if (state.type === pageTypes.customLogin && checkOneCustomLogin(state)) {
    const customLoginEntity = getByType(state, EntityTypeEnum.CustomLogin)[0]
    const confirmRegistrationStructure = getEntityById(
      state,
      customLoginEntity.childIds[1],
    )
    const confirmRegistrationEntities = getChildrenEntities(
      state,
      confirmRegistrationStructure.childIds,
    )
    let sctructureChecker = []
    confirmRegistrationEntities.forEach(entity => {
      if (entity.type === structureTypes.FORM_INPUT) {
        if (
          entity.options.slug === slugs.PASSWORD ||
          entity.options.slug === slugs.CONFIRM_PASSWORD
        ) {
          sctructureChecker.push(1)
        }
      }
      if (
        entity.type === structureTypes.BUTTON &&
        entity.options.action === buttonActionsTypes.confirmRegistration
      ) {
        sctructureChecker.push(1)
      }
    })
    // check if there extra or less required entities
    return sctructureChecker.join() == [1, 1, 1].join()
  }
  return true
}

const checkCustomLoginHasNotMultipleFieldsInRessetingMode = state => {
  if (state.type === pageTypes.customLogin && checkOneCustomLogin(state)) {
    const customLoginEntity = getByType(state, EntityTypeEnum.CustomLogin)[0]
    const resettingStructure = getEntityById(
      state,
      customLoginEntity.childIds[2],
    )
    const resettingEntities = getChildrenEntities(
      state,
      resettingStructure.childIds,
    )
    let sctructureChecker = []
    resettingEntities.forEach(entity => {
      if (
        entity.type === structureTypes.FORM_INPUT &&
        entity.options.slug === slugs.EMAIL
      ) {
        sctructureChecker.push(1)
      }
      if (
        entity.type === structureTypes.BUTTON &&
        entity.options.action === buttonActionsTypes.resetPassword
      ) {
        sctructureChecker.push(1)
      }
    })
    // check if there extra or less required entities
    return sctructureChecker.join() == [1, 1].join()
  }
  return true
}

function checkUniqueOptInRecaptcha(state) {
  let isPopupRecaptchaOnlyOne = true
  const pageRecaptchas = getByType(state, EntityTypeEnum.OptInRecaptcha)
  //check recaptchas in popups
  pageRecaptchas.forEach(entity => {
    const ascendantPopup = entityUtils.getAscendantPopup(state, entity)
    if (ascendantPopup && isPopupRecaptchaOnlyOne) {
      const visibleOptInRecaptchas = entityUtils.getVisibleOptInRecaptchas(
        state,
        ascendantPopup.id,
      )
      isPopupRecaptchaOnlyOne = visibleOptInRecaptchas.length <= 1
    }
  })
  //check recaptchas on the page content
  const isOptInRecaptchaOnlyOneMobile =
    entityUtils.getVisibleOptInRecaptchas(state, false).length <= 1
  //check blog post listings visible in desktop
  const isOptInRecaptchaOnlyOneDesktop =
    entityUtils.getVisibleOptInRecaptchas(state, true).length <= 1

  return (
    isOptInRecaptchaOnlyOneMobile &&
    isOptInRecaptchaOnlyOneDesktop &&
    isPopupRecaptchaOnlyOne
  )
}

// const hasEmailSlug = entity => entity.options.slug === slugs.EMAIL

// const checkEveryFormHasEmail = state => getByType(state, FORM)
//   .every(form => findDescendantsByType(state, form.id, FORM_INPUT).some(hasEmailSlug))
//
// const checkEveryInputHasTypeId = state => getByType(state, FORM_INPUT)
//   .every(hasInputTypeId)

// export const validatePage = checker(
//   validator('some forms not contain email input', checkEveryFormHasEmail),
//   validator('some forms contain empty input type.', checkEveryInputHasTypeId)
// )
export const validatePage = checker(
  validator(
    'validation.page.content_length_exceeded',
    checkContentLengthNotExceeded,
  ),
  validator(
    'validation.page.multiple_payment_methods',
    checkHasNotMultiplePaymentMethods,
  ),
  validator(
    'validation.page.product_element_required',
    checkHasProductElementWhenStateHasProduct,
  ),
  // validator(
  //   'validation.page.has_unused_or_extra_fields',
  //   checkNoUnUsedExtraFields,
  // ),
  validator(
    'validation.page.blog_post_content.one_placeholder',
    checkBlogPostContentOnePlaceholder,
  ),
  validator(
    'validation.page.blog_post_listing_unique',
    checkUniqueBlogPostListing,
  ),
  validator(
    'validation.page.blog_layout.one_placeholder',
    checkBlogPageLayoutOnePlaceholder,
  ),
  validator(
    'validation.page.custom_login.one_custom_login_element',
    checkOneCustomLogin,
  ),
  validator(
    'validation.page.custom_login.login_form_required_elements',
    checkCustomLoginHasNotMultipleFieldsInLoginMode,
  ),
  validator(
    'validation.page.calendar.one_element',
    checkHasNotMultipleCalendars,
  ),
  validator(
    'validation.page.calendar.event_not_selected',
    checkCalendarEventSelected,
  ),
  validator(
    'validation.page.custom_login.confirm_registration_form_required_elements',
    checkCustomLoginHasNotMultipleFieldsInConfirmRegistrationMode,
  ),
  validator(
    'validation.page.custom_login.resetting_form_required_elements',
    checkCustomLoginHasNotMultipleFieldsInRessetingMode,
  ),
  validator('validation.page.recaptcha_unique', checkUniqueOptInRecaptcha),
)

export const validateFormInputs = (page, paymentMethods, inputTypes) => {
  if (page.type !== pageTypes.offerPage) {
    return
  }
  const fixedT = i18n.getFixedT(page.locale)

  const fields = getByType(page, structureTypes.FORM_INPUT)
  const desktopFields = fields.filter(
    entity => entity.options.appearance.desktop,
  )
  const mobileFields = fields.filter(entity => entity.options.appearance.mobile)
  const fieldSlugs = fields.map(entity => entity.options.slug)

  let isAllRequiredFieldsAdded = {}

  paymentMethods.forEach(paymentMethod => {
    const requiredFields = paymentProcessorsRequiredFields[paymentMethod] || []
    requiredFields.forEach(fieldType => {
      if (
        !fieldSlugs.includes(fieldType) &&
        !isAllRequiredFieldsAdded[fieldType]
      ) {
        isAllRequiredFieldsAdded[fieldType] = inputTypes[fieldType].name
      }
    })
  })

  const requiredFieldNamesArr = Object.values(isAllRequiredFieldsAdded)

  if (requiredFieldNamesArr.length) {
    if (requiredFieldNamesArr.length === 1) {
      throw new PageValidationErrorNew(
        fixedT('validation.page.offer_form.form_inputs_required', {
          fieldName: requiredFieldNamesArr[0],
          count: requiredFieldNamesArr.length,
        }),
      )
    } else {
      throw new PageValidationErrorNew(
        fixedT('validation.page.offer_form.form_inputs_required', {
          fieldNames: requiredFieldNamesArr.join(','),
          count: requiredFieldNamesArr.length,
        }),
      )
    }
  }
}
