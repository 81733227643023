import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { faqListIcons } from 'common/constants/iconList'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import FaqInterface, {
  FaqItemInterface,
} from 'common/types/entities/FaqInterface'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { createFaqAnswer } from 'client/components/entities/Faq'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store'
import { updateEntityInnerItem } from 'client/store/page/pageActions'
import pageSelectors from 'client/store/page/pageSelectors'
import ColorPicker from '../../components/ColorPicker'
import EntityInnerItems from '../../components/EntityInnerItems'
import TextFontUpdater from '../../components/Font/TextFontUpdater'
import IconPicker from '../../components/IconPicker'
import IconPickerWithRemoveButton from '../../components/IconPickerWithRemoveButton'
import Range from '../../components/Range'
import { useUpdateProp, useUpdateEntity } from '../../hooks/useUpdateProps'
import FontSize from '../../styles/FontSize'
import MarginStyle from '../../styles/MarginStyle'
import ShadowStyle from '../../styles/ShadowStyle'
import MobileIconUi from '../../styles/ui/MobileIconUi'
import FaqItemInputUi from './ui/FaqItemInputUi'

type FaqSettingsProps = {
  entity: FaqInterface
}

function FaqSettings({ entity }: FaqSettingsProps) {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<FaqInterface>()
  const { isMobile } = useDeviceModeContext()
  const dispatch = useDispatch()
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const { i18n } = useTranslation()

  function updateFaqItemTitle(innerItem: FaqItemInterface) {
    return function (e: React.BaseSyntheticEvent) {
      dispatch(updateEntityInnerItem({ ...innerItem, title: e.target.value }))
    }
  }

  function createEntityInnerItem(
    parentId: string,
    masterBlockId?: MasterBlockId,
    creationItemNumber?: number,
  ) {
    const faqAnswer = createFaqAnswer(
      parentId,
      masterBlockId,
      creationItemNumber,
    )
    faqAnswer.title = i18n.getFixedT(pageLocale)(
      'entity_settings.faq_item.default_title',
    )
    return faqAnswer
  }

  const calculatedShowIcons =
    entity.showIcons === undefined ? true : entity.showIcons
  const calculatedMobileShowIcons =
    entity.mobileShowIcons === undefined ? true : entity.mobileShowIcons

  return (
    <div>
      <EntityInnerItems<FaqItemInterface>
        entity={entity}
        createEntityInnerItem={createEntityInnerItem}
        removeWarningText="entity_settings.faq_item.remove_warning"
        renderInnerItem={(innerItem, switchToItem) => (
          <FaqItemInputUi
            value={innerItem.title}
            onChange={updateFaqItemTitle(innerItem)}
            onClick={switchToItem}
          />
        )}
        addItemButtonText="entity_settings.faq_item.add"
      />
      <Range
        // @ts-ignore
        labelText="entity_settings.faq.space_between.label"
        value={entity.spaceBetweenItems}
        update={updateProp('spaceBetweenItems')}
        min={0}
        max={50}
      />
      <Range
        // @ts-ignore
        labelText="entity_settings.faq.border_radius.label"
        value={entity.itemBorderRadius}
        update={updateProp('itemBorderRadius')}
        min={0}
        max={20}
      />
      <Checkbox
        labelText={'entity_settings.faq.show_icons'}
        labelIcon={isMobile && <MobileIconUi />}
        update={
          isMobile ? updateProp('mobileShowIcons') : updateProp('showIcons')
        }
        value={isMobile ? calculatedMobileShowIcons : calculatedShowIcons}
        directionRow={false}
        helpMessage={''}
        needTranslate
        inverse={false}
      />
      <IconPicker
        labelText="entity_options.icon_picker.title_collapsible_opened"
        iconList={faqListIcons}
        value={entity.iconClassName}
        update={updateProp('iconClassName')}
        searchPlaceholder="settings.components.icon_picker.placeholder"
      />
      <IconPickerWithRemoveButton
        label="entity_options.icon_picker.title_collapsible_closed"
        iconList={faqListIcons}
        value={entity.closedIconClassName}
        update={updateProp('closedIconClassName')}
      />
      <TextFontUpdater<FaqInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'titleFontFamily'}
        fontWeightKey={'titleFontWeight'}
        fontStyleKey={'titleFontStyle'}
        mobileFontFamilyKey={'titleFontFamily'}
        mobileFontWeightKey={'titleFontWeight'}
        mobileFontStyleKey={'titleFontStyle'}
      />
      <FontSize
        labelText="entity_settings.faq.title_size.label"
        fontSize={entity.titleFontSize}
        mobileFontSize={entity.mobileTitleFontSize}
        update={updateProp('titleFontSize')}
        mobileUpdate={updateProp('mobileTitleFontSize')}
      />
      <ColorPicker
        update={updateProp('titleColor')}
        mobileUpdate={updateProp('mobileTitleColor')}
        color={entity.titleColor}
        mobileColor={entity.mobileTitleColor}
        label="entity_settings.faq.title.label"
      />
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="entity_settings.faq_background.label"
      />
      <ColorPicker
        update={updateProp('iconColor')}
        mobileUpdate={updateProp('mobileIconColor')}
        color={entity.iconColor}
        mobileColor={entity.mobileIconColor}
        label="icon color"
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <PaddingStyle
        label="entity_settings.faq.item_padding.label"
        padding={isMobile ? entity.mobileItemPadding : entity.itemPadding}
        update={updateProp(isMobile ? 'mobileItemPadding' : 'itemPadding')}
      />
    </div>
  )
}

export default FaqSettings
