import EntityTypeEnum from 'common/enums/entityTypeEnum'

const lockedEntities = [
  EntityTypeEnum.CustomLoginForgotPassword,
  EntityTypeEnum.ContactUs,
  EntityTypeEnum.ContactUsField,
  EntityTypeEnum.ContactUsRecaptcha,
]

export const isEntityLocked = (entity: EntityTypeEnum): boolean => {
  return lockedEntities.includes(entity)
}
