export const CREATE_ENTITY = 'CREATE_NODE'
export const CREATE_STRUCTURE = 'CREATE_STRUCTURE'
export const CREATE_DESTINATION_STRUCTURE = 'CREATE_DESTINATION_STRUCTURE'
export const UPDATE_ENTITY = 'UPDATE_ENTITY'
export const MOVE_NODE = 'MOVE_NODE'
export const MOVE_NODE_NEW = 'MOVE_NODE_NEW'
export const MOVE_DOWN = 'MOVE_DOWN'
export const MOVE_UP = 'MOVE_UP'
export const REMOVE_NODE = 'REMOVE_NODE'
export const REMOVE_POPUP = 'REMOVE_POPUP'
export const CREATE_POPUP_STRUCTURE = 'CREATE_POPUP_STRUCTURE'
export const ADD_CHILD = 'ADD_CHILD'
export const ADD_STRUCTURE_ASCENDANT_AS_CHILD =
  'ADD_STRUCTURE_ASCENDANT_AS_CHILD'
export const REMOVE_CHILD = 'REMOVE_CHILD'
export const HAS_LOST_ENTITIES = 'HAS_LOST_ENTITIES'
export const HAS_MASTER_BLOCK_ROOT_WITHOUT_MASTER_BLOCK_ID =
  'HAS_MASTER_BLOCK_ROOT_WITHOUT_MASTER_BLOCK_ID'
export const HAS_MASTER_BLOCK_IDS_WITHOUT_MASTER_ROOT =
  'HAS_MASTER_BLOCK_IDS_WITHOUT_MASTER_ROOT'
export const REMOVE_LOST_ENTITIES = 'REMOVE_LOST_ENTITIES'
export const MARK_AS_MASTER_BLOCK = 'MARK_AS_MASTER_BLOCK'
export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS'
export const TOGGLE_PAGE_SETTINGS = 'TOGGLE_PAGE_SETTINGS'
export const TOGGLE_HIGHLIGHT = 'TOGGLE_HIGHLIGHT'
export const TOGGLE_POPUP_MANAGEMENT = 'TOGGLE_POPUP_MANAGEMENT'
export const TOGGLE_CODE_DIALOG = 'TOGGLE_CODE_DIALOG'
export const CLOSE_CODE_DIALOG = 'CLOSE_CODE_DIALOG'
export const ALLOW_CODE_DIALOG_PORTAL = 'ALLOW_CODE_DIALOG_PORTAL'
export const CHANGE_TWO_STEP_PAYMENT_FORM_STEP_TYPE =
  'CHANGE_TWO_STEP_PAYMENT_FORM_STEP_TYPE'
export const CHANGE_BOOKING_CALENDAR_STEP_TYPE =
  'CHANGE_BOOKING_CALENDAR_STEP_TYPE'
export const TOGGLE_ACTIVE_POPUP = 'TOGGLE_ACTIVE_POPUP'
export const TOGGLE_CREATE_BLOCK_DIALOG = 'TOGGLE_CREATE_BLOCK_DIALOG'
export const TOGGLE_SIDE_MENU_OPENED = 'TOGGLE_SIDE_MENU_OPENED'
export const TOGGLE_DROP_DOWN_MENU_OPENED = 'TOGGLE_DROP_DOWN_MENU_OPENED'
export const TOGGLE_INSTANT_UPLOAD = 'TOGGLE_INSTANT_UPLOAD'
export const SHOW_SURVEY_RESULT = 'SHOW_SURVEY_RESULT'
export const TOGGLE_SETTINGS_OVERLAY = 'TOGGLE_SETTINGS_OVERLAY'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_COLORS = 'FETCH_COLORS'
export const FETCH_HAS_FACEBOOK_CONVERSION_PIXEL =
  'FETCH_HAS_FACEBOOK_CONVERSION_PIXEL'

export const FETCH_PAGE = 'FETCH_PAGE'
export const SAVE_PAGE = 'SAVE_PAGE'
export const PREVIEW_PAGE = 'PREVIEW_PAGE'

export const FETCH_INPUT_TYPES = 'FETCH_INPUT_TYPES'
export const FETCH_EXIT_URL = 'FETCH_EXIT_URL'

export const START = '_START'
export const SUCCESS = '_SUCCESS'
export const FAIL = '_FAIL'

export const FETCH_PAGE_FILES = 'FETCH_PAGE_FILES'
export const FETCH_USER_FILES = 'FETCH_USER_FILES'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const ADD_FILE_TO_PAGE_FILES = 'ADD_FILE_TO_PAGE_FILES'
export const REMOVE_FILE = 'REMOVE_FILE'
export const SET_IS_ALL_FILES_FETCHED = 'SET_IS_ALL_FILES_FETCHED'

export const INCREMENT_FORM_COUNT = 'INCREMENT_FORM_COUNT'

export const CHANGE_PAGE_LOCALE = 'CHANGE_PAGE_LOCALE'

export const UPDATE_SEO = 'UPDATE_SEO'
export const UPDATE_SEO_PROPERTY = 'UPDATE_SEO_PROPERTY'
export const UPDATE_TRACKING = 'UPDATE_TRACKING'
export const UPDATE_TRACKING_PROPERTY = 'UPDATE_TRACKING_PROPERTY'
export const UPDATE_GLOBAL_SETTING = 'UPDATE_PAGE_SETTING'

export const EXIT = 'EXIT'
export const RESET_PAGE_ERROR = 'RESET_PAGE_ERROR'
export const PAGE_VALIDATION_ERROR = 'PAGE_VALIDATION_ERROR'
export const PAGE_VALIDATION_ERROR_RESET = 'PAGE_VALIDATION_ERROR_RESET'

export const TOGGLE_NEED_SCROLL = 'TOGGLE_NEED_SCROLL'

export const FETCH_PAYMENT_DATA_SUCCESS = 'FETCH_PAYMENT_DATA_SUCCESS'
export const FETCH_PAYMENT_DATA_ERROR = 'FETCH_PAYMENT_DATA_ERROR'

export const FETCH_AGREEMENT = 'FETCH_AGREEMENT'
export const TOGGLE_DOUBLE_OPT_IN = 'TOGGLE_DOUBLE_OPT_IN'
