import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  EntityInnerItemInterface,
  EntityWithChildIdsInterface,
  MasterBlockId,
} from 'common/types/entities/EntityInterface'
import CoreButton from 'client/components/core/BlueButton'
import {
  managementSelectors,
  pageSelectors,
  useManagement,
  usePage,
} from 'client/store/index'
import { switchEntityInnerItemActiveIndex } from 'client/store/management/managementActions'
import {
  addStructure,
  removeEntityByIdAndParentId,
} from 'client/store/page/pageActions'
import PrimaryButton from '../../components/PrimaryButton'
import { useUpdateProp } from '../../hooks/useUpdateProps'
import EntityInnerItemRemoveIconUi from './ui/EntityInnerItemRemoveIconUi'
import EntityInnerItemShowIconUi from './ui/EntityInnerItemShowIconUi'
import EntityInnerItemSortIconUi from './ui/EntityInnerItemSortIconUi'
import EntityInnerItemUi from './ui/EntityInnerItemUi'
import EntityInnerItemsControlsUi from './ui/EntityInnerItemsControlsUi'
import EntityInnerItemsUi from './ui/EntityInnerItemsUi'

type EntityInnerItemsProps<T extends EntityInnerItemInterface> = {
  entity: EntityWithChildIdsInterface
  children?: React.ReactChildren
  createEntityInnerItem: (
    parentId: string,
    masterBlockId?: MasterBlockId,
    creationItemNumber?: number,
  ) => T
  renderInnerItem?: (
    innerItem: T,
    switchToItem: () => void,
    isActive: boolean,
  ) => React.ReactElement
  removeWarningText?: string
  addItemButtonText: string
}

function EntityInnerItems<T extends EntityInnerItemInterface>({
  entity,
  createEntityInnerItem,
  renderInnerItem,
  addItemButtonText,
  removeWarningText,
}: EntityInnerItemsProps<T>) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const updateProp = useUpdateProp(entity)
  const activeIndex = useManagement(management =>
    managementSelectors.getEntityInnerItemActiveIndex(management, entity.id),
  )
  const innerItems = usePage(page =>
    pageSelectors.getChildrenEntities(page, entity.childIds),
  ) as T[]

  function sortUp(index: number) {
    return () => {
      if (index === activeIndex) {
        switchToItem(index - 1)()
      }
      updateProp('childIds')([
        ...entity.childIds.slice(0, index - 1),
        entity.childIds[index],
        entity.childIds[index - 1],
        ...entity.childIds.slice(index + 1),
      ])
    }
  }

  function sortDown(index: number) {
    return () => {
      if (index === activeIndex) {
        switchToItem(index + 1)()
      }
      updateProp('childIds')([
        ...entity.childIds.slice(0, index),
        entity.childIds[index + 1],
        entity.childIds[index],
        ...entity.childIds.slice(index + 2),
      ])
    }
  }

  function removeItem(index: number) {
    return () => {
      if (
        confirm(
          t(
            removeWarningText ||
              'entity_settings.inner_item.remove_item_warning',
          ),
        )
      ) {
        switchToItem(innerItems[index - 1] ? index - 1 : 0)()
        dispatch(removeEntityByIdAndParentId(entity.childIds[index], entity.id))
      }
    }
  }

  function addItem() {
    const innerItem = createEntityInnerItem(
      entity.id,
      entity.masterBlockId,
      innerItems[innerItems.length - 1].creationItemNumber + 1,
    )
    dispatch(
      addStructure({
        [innerItem.id]: innerItem,
        [entity.id]: {
          ...entity,
          childIds: [...entity.childIds, innerItem.id],
        },
      }),
    )
    switchToItem(entity.childIds.length)()
  }

  function switchToItem(activeIndex: number) {
    return () =>
      dispatch(switchEntityInnerItemActiveIndex(entity.id, activeIndex))
  }

  return (
    <>
      <EntityInnerItemsUi>
        {innerItems.map((innerItem, index, arr) => (
          <EntityInnerItemUi key={innerItem.id}>
            {index + 1}
            {renderInnerItem ? (
              renderInnerItem(
                innerItem,
                switchToItem(index),
                index === activeIndex,
              )
            ) : (
              <CoreButton
                onClick={switchToItem(index)}
                // @ts-ignore
                styles={{
                  marginRight: 'auto',
                  ...(index !== activeIndex ? { background: '#bcbcbc' } : {}),
                }}
              />
            )}
            <EntityInnerItemShowIconUi
              className="far fa-eye"
              onClick={switchToItem(index)}
              active={activeIndex === index}
            />
            <EntityInnerItemsControlsUi>
              {index !== 0 && (
                <EntityInnerItemSortIconUi
                  className="fas fa-chevron-up"
                  key="up"
                  onClick={sortUp(index)}
                />
              )}
              {index + 1 !== arr.length && (
                <EntityInnerItemSortIconUi
                  className="fas fa-chevron-down"
                  key="down"
                  onClick={sortDown(index)}
                />
              )}
            </EntityInnerItemsControlsUi>
            <EntityInnerItemRemoveIconUi
              disabled={activeIndex !== index || arr.length === 1}
              onClick={removeItem(index)}
            />
          </EntityInnerItemUi>
        ))}
      </EntityInnerItemsUi>
      <PrimaryButton onClick={addItem}>{addItemButtonText}</PrimaryButton>
    </>
  )
}

export default EntityInnerItems
