import { TFunction } from 'i18next'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createBookingCalendar = (
  t: TFunction,
  parentId: string,
  masterBlockId?: MasterBlockId,
): BookingCalendarInterface => {
  const entity: BookingCalendarInterface = {
    ...generateBaseEntity(
      EntityTypeEnum.BookingCalendar,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.BookingCalendar,

    padding: {
      paddingTop: 30,
      paddingRight: 30,
      paddingBottom: 30,
      paddingLeft: 30,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    margin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },

    eventId: undefined,
    availableSlotBackgroundColor: 'rgba(241, 244, 248, 1)',
    selectedSlotBackgroundColor: 'rgba(0, 160, 255, 1)',
    titlesColor: 'rgba(20, 45, 99, 1)',
    mobileTitlesColor: 'rgba(20, 45, 99, 1)',
    color: 'rgba(112, 112, 112, 1)',
    mobileColor: 'rgba(112, 112, 112, 1)',

    calendarStepHeadline: t(
      'entities.calendar.calendar_step_headline_default_text',
    ),
    calendarStepSubHeadline: t(
      'entities.calendar.calendar_step_sub_headline_default_text',
    ),
    formStepHeadline: t('entities.calendar.form_step_headline_default_text'),
    formStepSubHeadline: t(
      'entities.calendar.form_step_sub_headline_default_text',
    ),
    nextStepButtonText: t('entities.calendar.next_step_default_text'),
    formButtonText: t('entities.calendar.form_submit_default_text'),

    childIds: [],
  }

  return entity
}
