import React from 'react'
import { BookingCalendarSteps } from 'common/components/entities/Calendar/constants'
import { useActions } from 'common/hooks/useActions'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { changeBookingCalendarStepType } from 'client/actions/actionsManagement'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import { Switcher } from '../../components'
import Input from '../../components/Input/Input'
import { useUpdateProp } from '../../hooks/useUpdateProps'

interface BookingStepsSwitcherProps {
  entity: BookingCalendarInterface
}

export const BookingStepsSwitcher = ({ entity }: BookingStepsSwitcherProps) => {
  const updateProp = useUpdateProp(entity)

  const stepType = useManagement(managementSelectors.getBookingCalendarStepType)
  const changeStep = useActions(changeBookingCalendarStepType)
  const isCalendarStep = stepType === BookingCalendarSteps.CalendarStep
  const isFormStep = stepType === BookingCalendarSteps.FormStep

  return (
    <React.Fragment>
      <Switcher
        handleFirstButton={() => changeStep(BookingCalendarSteps.CalendarStep)}
        isFirstActive={isCalendarStep}
        firstLabel="entity_settings.booking_calendar.switch_calendar_step"
        handleSecondButton={() => changeStep(BookingCalendarSteps.FormStep)}
        isSecondActive={isFormStep}
        secondLabel="entity_settings.booking_calendar.switch_form_step"
      />
      {isCalendarStep && (
        <React.Fragment>
          <Input
            value={entity.calendarStepHeadline}
            update={updateProp('calendarStepHeadline')}
            label="entity_settings.booking_calendar.headline"
          />
          <Input
            value={entity.calendarStepSubHeadline}
            update={updateProp('calendarStepSubHeadline')}
            label="entity_settings.booking_calendar.sub_headline"
          />
          <Input
            value={entity.nextStepButtonText}
            update={updateProp('nextStepButtonText')}
            label="entity_settings.booking_calendar.next_step_button_text"
          />
        </React.Fragment>
      )}
      {isFormStep && (
        <React.Fragment>
          <Input
            value={entity.formStepHeadline}
            update={updateProp('formStepHeadline')}
            label="entity_settings.booking_calendar.headline"
          />
          <Input
            value={entity.formStepSubHeadline}
            update={updateProp('formStepSubHeadline')}
            label="entity_settings.booking_calendar.sub_headline"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
