import { useState, useEffect } from 'react'
import { MyEventInterface } from 'common/components/entities/Calendar/calendar-interface'
import { getMyEvents } from 'client/api/booking-calendar-api'

export const useMyEvents = ({
  onSuccess,
}: {
  onSuccess: (events: MyEventInterface[]) => void
}) => {
  const [events, setEvents] = useState<MyEventInterface[]>([])
  const [error, setError] = useState<unknown>(null)
  const [isFetching, setIsFetching] = useState(false)

  const fetch = async () => {
    try {
      setIsFetching(true)
      const data = await getMyEvents()
      setEvents(data.data)
      onSuccess(data.data)
    } catch (error) {
      setError(error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return { events, isFetching, error, fetch }
}
