import React, { createContext, useContext } from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import CustomLoginInterface from 'common/types/entities/CustomLoginInterface'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useCustomLogin, usePage } from 'client/store'
import { selectors } from 'client/store/custom-login/customLoginReducer'
import { generateHtmlAttrId, generateId } from 'client/utils/createStructureNew'
import BaseEntity from '../../../components/core/BaseEntity/BaseEntityNew'
import CustomLoginContainerUi from '../../../components/entities/CustomLogin/ui/CustomLoginContainerUi'
import pageSelectors from '../../../store/page/pageSelectors'

type NewCustomLoginProps = {
  entity: CustomLoginInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

export enum CustomLoginModeEnum {
  Login = 'login',
  Registration = 'registration',
  Request = 'request',
}

const CustomLoginDescendantIdsContext = createContext([] as string[])

type CustomLoginDescendantIdsProviderProps = {
  children: React.ReactElement
  entityId: string
}

export const useCustomLoginDescendantIds = () =>
  useContext(CustomLoginDescendantIdsContext)

function CustomLoginDescendantIdsProvider({
  children,
  entityId,
}: CustomLoginDescendantIdsProviderProps) {
  const descendantIds = usePage(page =>
    pageSelectors.getDescendantIds(page, entityId),
  ).concat([entityId])
  return (
    <CustomLoginDescendantIdsContext.Provider value={descendantIds}>
      {children}
    </CustomLoginDescendantIdsContext.Provider>
  )
}

function CustomLogin({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: NewCustomLoginProps) {
  const mode = useCustomLogin(selectors.getCustomLoginMode)
  let activeChildId
  switch (mode) {
    case CustomLoginModeEnum.Login:
      activeChildId = entity.childIds[0]
      break
    case CustomLoginModeEnum.Registration:
      activeChildId = entity.childIds[1]
      break
    case CustomLoginModeEnum.Request:
      activeChildId = entity.childIds[2]
      break
    default:
      activeChildId = entity.childIds[0]
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CustomLoginContainerUi
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
      >
        <CustomLoginDescendantIdsProvider entityId={entity.id}>
          <ChildrenEntities
            id={entity.id}
            type={entity.type}
            childIds={[activeChildId]}
          />
        </CustomLoginDescendantIdsProvider>
      </CustomLoginContainerUi>
    </BaseEntity>
  )
}
export function createNewCustomLogin(
  parentId: string,
  masterBlockId?: MasterBlockId,
): CustomLoginInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.CustomLogin,
    parentId: parentId,
    masterBlockId: masterBlockId,
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 5,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.CustomLogin),
    childIds: [],
  }
}
export default CustomLogin
