import React from 'react'
import CommonBookingCalendar from 'common/components/entities/Calendar'
import { EventTimeZoneTypeEnum } from 'common/components/entities/Calendar/constants'
import { useEvent } from 'common/components/entities/Calendar/hooks/use-event'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { getLocalTimeZone } from 'common/utils/date-utils'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import EntityExampleWrapperNew from 'client/components/core/EntityExampleWrapper/EntityExampleWrapperNew'
import useSettingsManagement, { selectors } from 'client/hooks/useManagement'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import pageSelectors from 'client/store/page/pageSelectors'
import { EntityProps } from 'client/types'
import { mockBooking } from './mock-booking'

export const BookingCalendar = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BookingCalendarInterface>) => {
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const activeStep = useSettingsManagement(selectors.getBookingCalendarStepType)

  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalFontFamily = usePage(pageSelectors.getGlobalFontFamily)

  const userTimeZone = useManagement(managementSelectors.getUserTimezone)

  const { event, isFetching } = useEvent({
    eventId: entity.eventId,
    scope: 'client',
  })

  const selectedTimeZone =
    event?.displayTimezoneType === EventTimeZoneTypeEnum.Local
      ? userTimeZone
      : event?.displayTimezone || getLocalTimeZone()

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <EntityExampleWrapperNew
        visible={!entity.eventId}
        label="entities.calendar.example_warning"
      >
        <CommonBookingCalendar
          selectedDate={mockBooking.selectedDate}
          selectedTimeSlot={mockBooking.selectedTimeSlot}
          selectedLocationId={event?.locations?.[0]?.id}
          selectedTimeZone={selectedTimeZone}
          isLoading={isFetching}
          availabilitySlots={mockBooking.availabilitySlots}
          event={event}
          fontSize={globalFontSize}
          mobileFontSize={globalMobileFontSize}
          fontFamily={globalFontFamily}
          mobileFontFamily={globalFontFamily}
          activeStep={activeStep}
          entity={entity}
          formStepChildren={
            <ChildrenEntities
              id={entity.id}
              childIds={entity.childIds as never[]} // we need to migrate ChildrenEntities to ts to avoid
              type={entity.type}
            />
          }
        />
      </EntityExampleWrapperNew>
    </BaseEntityNew>
  )
}
