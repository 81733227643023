import React from 'react'
import Button from 'common/components/entities/Button/Button'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { MasterBlockId } from 'common/types/entities/EntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export interface PaymentButtonProps {
  entity: PaymentButtonInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
  isRemoveAllowed: boolean
  isSaveAllowed: boolean
  isCopyAllowed: boolean
  dropBoxAllowedEntityIds?: string[]
}

const PaymentButtonNew = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
  isRemoveAllowed,
  isSaveAllowed,
  isCopyAllowed,
  dropBoxAllowedEntityIds,
}: PaymentButtonProps) => {
  return (
    <BaseEntityNew
      entity={entity}
      flex
      flexColumn
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
      isRemoveAllowed={isRemoveAllowed}
      isSaveAllowed={isSaveAllowed}
      isCopyAllowed={isCopyAllowed}
      dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
    >
      <Button
        attrId={entity.htmlAttrId}
        text={entity.text}
        subText={entity.subText}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        textColor={entity.textColor}
        mobileTextColor={entity.mobileTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        textFontFamily={entity.textFontFamily}
        textFontStyle={entity.textFontStyle}
        textFontWeight={entity.textFontWeight}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        subTextColor={entity.subTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontStyle={entity.subTextFontStyle}
        subTextFontWeight={entity.subTextFontWeight}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
      />
    </BaseEntityNew>
  )
}
export default PaymentButtonNew

export const createPaymentButton = (
  parentId: string,
  masterBlockId?: MasterBlockId,
  loadingText?: string,
  text?: string,
  subText?: string,
): PaymentButtonInterface => {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.PaymentButton,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.PaymentButton,
    parentId: parentId,
    alignSelf: 'center',
    textFontSize: 20,
    border: {
      width: 1,
      style: 'none',
    },
    backgroundColor: 'rgba(1, 116, 199, 1)',
    textColor: 'rgba(255, 255, 255, 1)',
    padding: {
      paddingTop: 9,
      paddingRight: 25,
      paddingBottom: 9,
      paddingLeft: 25,
    },
    mobilePadding: {
      paddingTop: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    margin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 10,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0,
    },
    mobileTextFontSize: 20,
    mobileWidth: '100%',
    width: '100%',
    subTextFontSize: 14,
    loadingText,
    text: text ?? '',
    subText,
  }
}
